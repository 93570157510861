
import * as localImageSharpRuntime$6Ucp8U9TLB from '/Users/ulysse/Dev/Strapi-Nuxt-boilerplate/nuxt/providers/localImageSharp.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "localImageSharp",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['localImageSharp']: { provider: localImageSharpRuntime$6Ucp8U9TLB, defaults: {"baseURL":"https://strapi.ulysselacour.com/uploads/"} }
}
        